<template>
    <div>
        <el-card class="card-pub-notice card-ext-center-title">
            <div slot="header" class="notice_title">
                <span>{{ '投产申请' }}</span>
                <el-button @click="dataExport()" type="primary" style="float: right; margin-right: 20px"
                    >导出</el-button
                >
                <el-button @click="openFlowPage()" type="primary" style="float: right; margin-right: 20px"
                >流程展示
                </el-button
                >
            </div>
            <div>
                <div class="ulbox overhaul">
                    <table cellspacing="0px">
                        <tr>
                            <td colspan="8">
                                <p style="text-align: center; font-weight: 900">
                                    云南滇中新区配售电有限公司投产申请单
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="8">
                                <p>执行调度: {{this.overhaul.dispatchUnit}}</p>
                            </td>
                        </tr>
                        <!--申请填报-->
                        <tr v-if="this.overhaul.type == 1">
                            <td rowspan="17">申请填报</td>
                            <td>申请单编号</td>
                            <td>{{ this.overhaul.ticketNo }}</td>
                            <td>申请单位</td>
                            <td colspan="2">{{this.overhaul.applyUnit}}</td>
                            <td>提交时间</td>
                            <td>{{ this.overhaul.createTime }}</td>
                        </tr>
                        <tr v-else-if="this.overhaul.type == 2">
                            <td rowspan="24">申请填报</td>
                            <td>申请单编号</td>
                            <td>{{ this.overhaul.ticketNo }}</td>
                            <td>申请单位</td>
                            <td colspan="2">滇中新区配售电有限公司</td>
                            <td>提交时间</td>
                            <td>{{ this.overhaul.createTime }}</td>
                        </tr>

                        <tr>
                            <td>申请填报人</td>
                            <td>{{ this.overhaul.applyBy }}</td>
                            <td>申请填报人联系电话</td>
                            <td colspan="2">{{ this.overhaul.applyPN }}</td>
                            <td>检修类别</td>
                            <td>{{this.getOverType}}</td>
                        </tr>
                        <tr>
                            <td>许可方式及工作许可人</td>
                            <td colspan="6"></td>
                        </tr>
                        <tr v-if="this.overhaul.permissionType == 1">
                            <td>厂站工作许可</td>
                            <td>工作许可人</td>
                            <td colspan="2">
                                <div>{{ this.overhaul.licensorId }}</div>
                            </td>
                            <td>联系电话</td>
                            <td colspan="2">
                                <div>{{ this.overhaul.licensorMobile }}</div>
                            </td>
                        </tr>
                        <tr v-if="this.overhaul.permissionType == 2">
                            <td>线路工作许可</td>
                            <td>工作许可人</td>
                            <td colspan="2">
                                <div>{{ this.overhaul.licensorId }}</div>
                            </td>
                            <td>联系电话</td>
                            <td colspan="2">
                                <div>{{ this.overhaul.licensorMobile }}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>工作负责人</td>
                            <td>工作负责人</td>
                            <td colspan="2">{{ this.overhaul.chargeName }}</td>
                            <td>联系电话</td>
                            <td colspan="2">{{ this.overhaul.chargeTel }}</td>
                        </tr>
                        <tr>
                            <td>工作地点</td>
                            <td colspan="6">{{ this.overhaul.workAddress }}</td>
                        </tr>
                        <tr>
                            <td>附件</td>
                            <td colspan="6">
                                <div
                                    v-for="item in this.overhaul.attachList"
                                    v-bind:key="item"
                                    @click="downloadfile(item)"
                                    style="cursor: pointer; color: #0066ff"
                                >
                                    {{ item.name }}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>申请投产设备</td>
                            <td colspan="6">{{ this.overhaul.equipmentName }}</td>
                        </tr>
                        <tr>
                            <td>工作内容</td>
                            <td colspan="6">{{ this.overhaul.workContent }}</td>
                        </tr>
                        <tr>
                            <td rowspan="2">工作要求的安全措施</td>
                            <td>一次设备状态要求</td>
                            <td colspan="5">{{ this.overhaul.firstRequire }}</td>
                        </tr>
                        <tr>
                            <td>二次设备状态要求</td>
                            <td colspan="5">{{ this.overhaul.secondRequire }}</td>
                        </tr>
                        <tr>
                            <td>投产开始时间</td>
                            <td colspan="6">{{ this.overhaul.workBeginTime }}</td>
                        </tr>
                        <tr></tr>
                        <tr>
                            <td>涉及电网安全运行的影响</td>
                            <td colspan="6">{{ this.overhaul.securityEffect }}</td>
                        </tr>
                        <tr>
                            <td>涉及供电可靠性的影响</td>
                            <td colspan="6">{{ this.overhaul.reliabilityEffect }}</td>
                        </tr>
                        <tr>
                            <td>其他要求</td>
                            <td colspan="6">{{ this.overhaul.otherRequire }}</td>
                        </tr>
                        <tr>
                            <td>申请备注</td>
                            <td colspan="6">{{ this.overhaul.remark }}</td>
                        </tr>
                        <!--申请许可-->
                        <tr>
                            <td rowspan="4">申请许可</td>
                            <td>投产开始时间</td>
                            <td>{{ this.receive.allowCutBeginTime }}</td>
                            <td>受令人</td>
                            <td>{{ this.receive.allowOrderedPerson }}</td>
                            <td>调度员</td>
                            <td colspan="2">{{ this.receive.allowDispatcherBegin }}</td>
                        </tr>
                        <tr>
                        </tr>
                        <tr>
                            <td colspan="7">申请单所列的工作要求的安全措施已布置完成</td>
                        </tr>
                        <tr v-if="this.overhaul.permissionType == 1">
                            <td>厂站工作许可</td>
                            <td>工作许可人</td>
                            <td>
                                <div>{{ this.receive.allowLicensor }}</div>
                            </td>
                            <td>调度许可人</td>
                            <td>
                                <div>{{ this.receive.allowDispatcher }}</div>
                            </td>
                            <td>时间</td>
                            <td>
                                <div>{{ this.getTime(this.receive.allowTime) }}</div>
                            </td>
                        </tr>
                        <tr v-if="this.overhaul.permissionType == 2">
                            <td>线路工作许可</td>
                            <td>工作许可人</td>
                            <td>
                                <div>{{ this.receive.allowLicensor }}</div>
                            </td>
                            <td>调度许可人</td>
                            <td>
                                <div>{{ this.receive.allowDispatcher }}</div>
                            </td>
                            <td>时间</td>
                            <td>
                                <div>{{ this.getTime(this.receive.allowTime) }}</div>
                            </td>
                        </tr>

                        <!--申请变更-->
                        <tr v-if="this.receive.changePermissionType!=''">
                            <td rowspan="2">申请变更</td>
                            <td colspan="7">不需变更工作要求的安全措施下增加的工作内容</td>
                        </tr>
                        <tr v-if="this.receive.changePermissionType == 1">
                            <td>厂站工作许可</td>
                            <td>工作许可人</td>
                            <td>
                                <div >
                                    {{ this.receive.changeLicensor }}
                                </div>
                            </td>
                            <td>调度人许可人</td>
                            <td>
                                <div>
                                    {{ this.receive.changeDispatcher }}
                                </div>
                            </td>
                            <td>时间</td>
                            <td>
                                <div>
                                    {{ this.receive.changeAllowTime }}
                                </div>
                            </td>
                        </tr>
                        <tr v-if="this.receive.changePermissionType == 2">
                            <td>线路工作许可</td>
                            <td>工作许可人</td>
                            <td>
                                <div>
                                    {{ this.receive.changeLicensor }}
                                </div>
                            </td>
                            <td>调度人许可人</td>
                            <td>
                                <div>
                                    {{ this.receive.changeDispatcher }}
                                </div>
                            </td>
                            <td>时间</td>
                            <td>
                                <div>
                                    {{ this.receive.changeAllowTime }}
                                </div>
                            </td>
                        </tr>

                        <!--申请延期-->
                        <tr>
                            <td :rowspan="this.receive.delayPermissionType!=''?3:2">申请延期</td>
                            <td>延期原因</td>
                            <td colspan="6">{{ this.receive.delayReason }}</td>
                        </tr>
                        <tr>
                            <td>延期后工作结束时间</td>
                            <td>{{ this.receive.delayWorkTime }}</td>
                            <td>工作签发人</td>
                            <td colspan="4">{{ this.receive.delayIssuer }}</td>
                        </tr>
                        <tr v-if="this.receive.delayPermissionType == 1">
                            <td>厂站工作许可</td>
                            <td>工作许可人</td>
                            <td>
                                <div >{{ this.receive.delayLicensor }}</div>
                            </td>
                            <td>调度许可人</td>
                            <td>
                                <div>
                                    {{ this.receive.delayDispatcher }}
                                </div>
                            </td>
                            <td>时间</td>
                            <td>
                                <div>
                                    {{ this.receive.delayAllowTime }}
                                </div>
                            </td>
                        </tr>
                        <tr v-if="this.receive.delayPermissionType == 2">
                            <td>线路工作许可</td>
                            <td>工作许可人</td>
                            <td>
                                <div >{{ this.receive.delayLicensor }}</div>
                            </td>
                            <td>调度许可人</td>
                            <td>
                                <div>
                                    {{ this.receive.delayDispatcher }}
                                </div>
                            </td>
                            <td>时间</td>
                            <td>
                                <div>
                                    {{ this.receive.delayAllowTime }}
                                </div>
                            </td>
                        </tr>

                        <!--申请单的终结-->
                        <tr>
                            <td rowspan="4">申请单的终结</td>
                            <td colspan="7">
                                申请单中对应的所有现场工作票已终结，临时安全措施已拆除，作业人员已全部撤离，相关一二次设备运行正常
                            </td>
                        </tr>
                        <tr v-if="this.overhaul.permissionType == 1">
                            <td>厂站工作许可</td>
                            <td>工作许可人</td>
                            <td>
                                <div>{{ this.receive.finishLicensor }}</div>
                            </td>
                            <td>调度许可人</td>
                            <td>
                                <div>{{ this.receive.finishDispatcher }}</div>
                            </td>
                            <td>时间</td>
                            <td>
                                <div>{{ this.getTime(this.receive.finishAllowTime) }}</div>
                            </td>
                        </tr>
                        <tr v-if="this.overhaul.permissionType == 2">
                            <td>线路工作许可</td>
                            <td>工作许可人</td>
                            <td>
                                <div >{{ this.receive.finishLicensor }}</div>
                            </td>
                            <td>调度许可人</td>
                            <td>
                                <div>{{ this.receive.finishDispatcher }}</div>
                            </td>
                            <td>时间</td>
                            <td>
                                <div>{{ this.getTime(this.receive.finishAllowTime) }}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>投产结束时间</td>
                            <td>{{ this.getTime(this.receive.finishCutBeginTime) }}</td>
                            <td>受令人</td>
                            <td>{{ this.receive.finishOrderedPerson }}</td>
                            <td>调度员</td>
                            <td colspan="2">{{ this.receive.finishDispatcherBegin }}</td>
                        </tr>
                        <tr></tr>
                        <tr>
                            <td>调度备注</td>
                            <td colspan="7">
                                <p>{{ this.receive.remark }}</p>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </el-card>

        <div class="ui-layout-content--full">
            <el-dialog
                v-bind="flowPageDialog"
                width="760px"
                :title="flowPageDialog.title"
                v-if="flowPageDialog.visible"
                :visible.sync="flowPageDialog.visible"
                :modal-append-to-body="true"
                :close-on-click-modal="false"
                :append-to-body="true"
                :show-close="true"
            >
                <flow-page ref="FlowPage"></flow-page>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="flowPageDialog.visible = false" size="mini">关闭</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import {previewPdf, fileDownload} from '@/utils';
import dayjs from 'dayjs';
import FlowPage from './putIntoProductionFlowPage';

export default {
    components: {
        FlowPage,
    },
    data() {
        return {
            flowPageDialog: {
                visible: false,
                title: '流程',
            },
            uploadHeaders: {
                token: this.$store.state.token,
            },
            baseServer: this.axios.defaults.baseURL,
            workflowId: 0,
            businessFormKey: '',
            definitionCode: '',
            records: [],
            overhaul: {
                id: 0,
                drawingName: '',
                drawingNum: '',
                drawingStatus: '',
                applyId: '', //申请单号
                type: 1, //检修类型 1-计划内检修 2-非计划检修
                permissionType: '', //许可方式
                licensorId: '', //许可人id
                chargeName: '', //负责人姓名
                chargeTel: '', //负责人联系方式
                workAddress: '', //工作地点
                workContent: '', //工作内容
                firstRequire: '', //第一次设备状态要求
                secondRequire: '', //第二次设备状态要求
                workBeginTime: '', //工作开始时间
                workEndTime: '', //工作结束时间
                cutBeginTime: '', //停电开始时间
                cutEndTime: '', //停电结束时间
                securityEffect: '', //涉及电网安全运行的影响
                reliabilityEffect: '', //涉及供电可靠性的影响
                otherRequire: '', //其他要求
                remark: '', //备注
                permissionTypeName: '', //许可方式名称
                licensorName: '', //许可人名称
                licensorMobile: '', //许可人联系方式
                equipmentName: '', //设备名称
                attach: [], //附件
                createId: '', //申请人id
                createTime: '', //申请人时间
                createName: '', //申请人姓名
                createPhone: '', //申请人电话
                overhaulType: '', //检修类别 1-计划检修 2-新设备投产
            },
            receive: {
                allowCutBeginTime: '', //许可停电操作开始时间
                allowCutEndTime: '', //许可停电操作结束时间
                allowOrderedPerson: '', //许可受令人姓名
                allowReplyOrderPerson: '', //许可回令人姓名
                allowDispatcherBegin: '', //许可停电操作开始调度员姓名
                allowDispatcherEnd: '', //许可停电操作结束调度员姓名
                allowLicensor: '', //许可许可人
                allowDispatcher: '', //许可调度许可人
                allowTime: '', //许可时间
                changeContent: '', //变更内容
                changePermissionType: '', //变更许可方式
                changeLicensor: '', //变更许可人
                changeDispatcher: '', //变更调度许可人
                changeAllowTime: '', //变更调度许可事件
                delayReason: '', //延期原因
                delayWorkTime: '', //延期工作时间
                delayIssuer: '', //延期签发人
                delayPermissionType: '', //延期许可方式
                delayLicensor: '', //延期许可人
                delayDispatcher: '', //延期调度许可人
                delayAllowTime: '', //延期许可时间
                finishLicensor: '', //终结许可人
                finishDispatcher: '', //终结调度许可人
                finishAllowTime: '', //终结许可时间
                finishCutBeginTime: '', //终结停电开始时间
                finishCutEndTime: '', //终结停电结束时间
                finishOrderedPerson: '', //终结受令人
                finishReplyOrderPerson: '', //终结回令人
                finishDispatcherBegin: '', //终结停电开始调度员
                finishDispatcherEnd: '', //终结停电结束调度员
                remark: '', //备注
                changePermissionTypeName: '', //变更许可方式名称
                delayPermissionTypeName: '', //延期许可方式名称
            },
            classData: {
                colspan_9: 'colspan-9',
            },
            reviewList: [],
            row: {},
        };
    },
    methods: {
        init(row) {
            this.row = row;
            this.overhaul = {...this.overhaul, ...row.record_apply.data};
            if (row.record_receipt) {
                this.receive = {...this.receive, ...row.record_receipt.data};
            }
            this.records = row.records;
            this.definitionCode = row.definitionCode;
            this.overhaul.creator = row.record_apply.handler;
            if (row.record_apply.startTime) {
                this.overhaul.createTime = dayjs.utc(row.startTime).add(8, 'hours').format('YYYY-MM-DD HH:mm');
            }
            //待我处理、已处理查询workflowTask，带有workflowId字段；所有业务、我发起的查询workflow，workflowId为空，id即为workflowId
            this.workflowId = row.workflowId || row.id;
            this.businessFormKey = row.businessFormKey;
            this.initData();
            this.overhaul.createTime = dayjs(this.overhaul.createTime).format('YYYY-MM-DD HH:mm');
            this.overhaul.cutEndTime = dayjs(this.overhaul.cutEndTime).format('YYYY-MM-DD HH:mm');
            this.overhaul.workBeginTime = dayjs(this.overhaul.workBeginTime).format('YYYY-MM-DD HH:mm');
            this.overhaul.workEndTime = dayjs(this.overhaul.workEndTime).format('YYYY-MM-DD HH:mm');
            this.overhaul.cutBeginTime = dayjs(this.overhaul.cutBeginTime).format('YYYY-MM-DD HH:mm');
            if (row.record_receipt && row.record_receipt.data && row.record_receipt.data.allowCutBeginTime) {
                this.receive.allowCutBeginTime = dayjs(row.record_receipt.data.allowCutBeginTime).format('YYYY-MM-DD HH:mm');
            }
        },
        async bindData() {
            const data = await Promise.all([
                this.$client.getDicData('overhaul_permission_type'),
                this.$client.getSysUserByName(''),
            ]);
            //查询许可方式、许可人、许可人联系方式、检修设备
            // const enumParam = data[0].entity || [];
            this.overhaul.permissionTypeName = data[0].data.filter(e => e.itemKey === this.overhaul.permissionType)[0].itemValue;
            const licensor = data[1].list.filter(e => e.userId === this.overhaul.licensorId)[0];
            /*this.overhaul.licensorName = licensor.username;
            this.overhaul.licensorMobile = licensor.mobile;*/
            //申请人
            const creator = data[1].list.filter(e => e.username === this.overhaul.creator);
            if (creator.length > 0) {
                const user = creator[0];
                this.overhaul.createName = user.realName || '';
                this.overhaul.createPhone = user.mobile || '';
            }
        },
        async initData() {
            await this.bindData();
        },
        downloadfile(item) {
            fileDownload('/files/' + item.id + '?token=' + this.uploadHeaders.token);
        },
        getRecordData(code) {
            let result = this.records.filter(e => e.node.code.endsWith(code));
            result = result.sort((a, b) => b.endTime > a.endTime ? 1 : -1);
            if (result.length === 0) {
                return {};
            }
            return {
                ...result[0].data,
                reviewTime: dayjs.tz(result[0].endTime, 'UTC').tz(dayjs.tz.guess()).format('YYYY-MM-DD HH:mm')

            };
        },
        dataExport() {
            fileDownload('/business/putIntoProductions/download', {method: 'POST',
                data: {
                    type: this.definitionCode.indexOf('elec_production') > -1 ? '1' : '0',
                    o: {
                        ...this.overhaul,
                        workBeginTime: dayjs(this.overhaul.workBeginTime).format('YYYY-MM-DD HH:mm'),
                        workEndTime: dayjs(this.overhaul.workEndTime).format('YYYY-MM-DD HH:mm'),
                        cutBeginTime: dayjs(this.overhaul.cutBeginTime).format('YYYY-MM-DD HH:mm'),
                        cutEndTime: dayjs(this.overhaul.cutEndTime).format('YYYY-MM-DD HH:mm'),
                    },
                    r: {
                        ...this.receive,
                        allowCutBeginTime: this.receive.allowCutBeginTime ? dayjs(this.receive.allowCutBeginTime).format('YYYY-MM-DD HH:mm') : '',
                        allowCutEndTime: this.receive.allowCutEndTime ? dayjs(this.receive.allowCutEndTime).format('YYYY-MM-DD HH:mm') : '',
                        allowTime: this.receive.allowTime ? dayjs(this.receive.allowTime).format('YYYY-MM-DD HH:mm') : '',
                        changeAllowTime: this.receive.changeAllowTime ? dayjs(this.receive.changeAllowTime).format('YYYY-MM-DD HH:mm') : '',
                        delayWorkTime: this.receive.delayWorkTime ? dayjs(this.receive.delayWorkTime).format('YYYY-MM-DD HH:mm') : '',
                        delayAllowTime: this.receive.delayAllowTime ? dayjs(this.receive.delayAllowTime).format('YYYY-MM-DD HH:mm') : '',
                        finishAllowTime: this.receive.finishAllowTime ? dayjs(this.receive.finishAllowTime).format('YYYY-MM-DD HH:mm') : '',
                        finishCutBeginTime: this.receive.finishCutBeginTime ? dayjs(this.receive.finishCutBeginTime).format('YYYY-MM-DD HH:mm') : '',
                        finishCutEndTime: this.receive.finishCutEndTime ? dayjs(this.receive.finishCutEndTime).format('YYYY-MM-DD HH:mm') : '',
                    },
                    attachName: this.overhaul.attachList.length > 0 ? this.overhaul.attachList[0].name : '',
                    flowList: [],
                    f0: this.getRecordData('first_review'),
                    f1: this.getRecordData('second_review'),
                    f2: this.getRecordData('third_review'),
                    f3: this.getRecordData('fourth_review'),
                    f4: this.getRecordData('fifth_review'),
                    f5: this.getRecordData('sixth_review'),
                    f6: this.getRecordData('seventh_review'),
                    f7: this.getRecordData('eighth_review'),
                    f8: this.getRecordData('ninth_review'),
                    f9: this.getRecordData('tenth_review'),
                }});
        },
        openFlowPage() {
            this.flowPageDialog = {
                title: this.flowPageDialog.title,
                visible: true,
            };
            let row = this.row;
            this.$nextTick(() => {
                this.$refs.FlowPage.init(row);
            });
        },
    },
    computed: {
        getOverType() {
            let name = '';
            if (this.overhaul.overhaulType === '1') {
                name = '计划检修';
            } else if (this.overhaul.overhaulType === '2') {
                name = '新设备投产';
            }
            return name;
        },
        getTime() {
            return function(time) {
                return time ? dayjs(time).format('YYYY-MM-DD HH:mm') : '';
            };
        },
    }
};
</script>
<style lang="less" scoped>
table {
    width: 100%;
    padding-bottom: 15px;
}

table td {
    border: 0.1px #c0c0c0 solid;
    padding-left: 5px;
}

.overhaul table td {
    width: 10%;
}

.receive table td {
    width: 12.5%;
}

.line-height-200 {
    line-height: 200%;
}
</style>
